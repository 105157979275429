$(document).on("change", ".country-select-type", function () {
  var countryId = $(this).val();
  $.ajax({
    url: "/identification_types/new",
    type: "GET",
    dataType: "json",
    data: { country: countryId },
    error: function (xhr, status, error) {
      console.error("AJAX Error: " + status + error);
    },
    success: function (response) {
      const correspondents = response["entity_registrations"];
      if (correspondents.length > 0) {
        $(".corres-select-type").attr("disabled", false);
        $(".corres-select-type").removeClass("disabled");
        $(".corres-select-type").html("<option  selected value>ALL</option>");
        for (var i = 0; i < correspondents.length; i++) {
          $(".corres-select-type").append(
            '<option value="' +
              correspondents[i]["id"] +
              '">' +
              correspondents[i]["entity_registration"]["name"] +
              "</option>"
          );
        }
      } else {
        $(".corres-select-type").addClass("disabled");
        $(".corres-select-type").attr("disabled", true);
        $(".corres-select-type").html("<option  selected value>ALL</option>");
      }
    },
  });
});
