import consumer from "./consumer";

consumer.subscriptions.create("NotificationChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("connected");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log("Received notification:", data);
    const unreadCount = data.unread_count;
    $("#unread-notification-counts").text(unreadCount);

    // Construct the HTML for the new notification
    const newNotificationHtml = `
        <hr>
        <div class="p-5 hover:bg-primary hover:bg-opacity-5">
            <a href="/notifications/${
              data.id
            }/mark_as_read" data-method="patch">
                <div class="${data.read ? "text-black" : "text-primary"}">
                    <h6 class="uppercase">${data.heading}</h6>
                    <p>${data.message}</p>
                    <small>${data.created_at}</small>
                </div>
            </a>
        </div>
    `;

    // Append the new notification HTML to the notification list
    $("#notification-list").append(newNotificationHtml);
  },
});
